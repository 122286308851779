import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import ui from '@alpinejs/ui';
import anchor from '@alpinejs/anchor';
import '../../vendor/wire-elements/pro/resources/dist/js/overlay-component.js';

import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/l10n/nl.js';
import 'flatpickr/dist/l10n/fr.js';
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';

import { Calendar } from '@fullcalendar/core';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import interactionPlugin from '@fullcalendar/interaction';

import parsePhoneNumber from 'libphonenumber-js';

import '@nextapps-be/livewire-sortablejs';

import * as Sentry from "@sentry/browser";

import tippy from 'tippy.js';

Sentry.init({
    dsn: window.sentry_dsn,
    release: window.sentry_release,
    environment: window.sentry_environment,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: window.sentry_traces_sample_rate,
});

Alpine.plugin(ui);
Alpine.plugin(anchor);

import.meta.glob([
    '../images/**',
    '../fonts/**',
]);

window.flatpickr = flatpickr;
window.ShortcutButtonsPlugin = ShortcutButtonsPlugin;
window.Calendar = Calendar;
window.resourceTimeGridPlugin = resourceTimeGridPlugin;
window.scrollGridPlugin = scrollGridPlugin;
window.interactionPlugin = interactionPlugin;
window.parsePhoneNumber = parsePhoneNumber;

window.getFlatpickrDateFormat = ($locale, withTime = false) => {
    let format = withTime ? {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    } : {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }

    return new Intl.DateTimeFormat($locale, format).formatToParts(new Date()).map(({type, value}) => {
        switch (type) {
            case "day": return "d";
            case "month": return "m";
            case "year": return "Y";
            case "hour": return "H";
            case "minute": return "i";
            case "dayPeriod": return "K";
            default: return value;
        }
    }).join('');
}

window.dateToIsoString = (date) => {
    let timezoneOffset = -date.getTimezoneOffset();
    let pad = (num) => (num < 10 ? '0' : '') + num;

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        (timezoneOffset>= 0 ? '+' : '-') + pad(Math.floor(Math.abs(timezoneOffset) / 60)) +
        ':' + pad(Math.abs(timezoneOffset) % 60);
}

Alpine.magic('tooltip', el => (message, timeout = 2000) => {
    let instance = tippy(el, { content: message, trigger: 'manual' })

    instance.show()

    setTimeout(() => {
        instance.hide()

        setTimeout(() => instance.destroy(), 150)
    }, timeout)
})

Alpine.directive('tooltip', (el, { expression }) => {
    tippy(el, { content: expression })
})

Alpine.magic('clipboard', () => subject => {
    navigator.clipboard.writeText(subject)
})

Livewire.start();
